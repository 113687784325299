
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {ArrowRightIcon} from '../../../assets/icons'
import {media} from '../../../utils'


const StyledArrowContainer = styled.div`
  transform-origin: ${({$inverted}) => $inverted ? 'right' : 'left'};
  transition: ${({theme}) => `transform ${theme.global.duration} ease-in`};
  margin-right: 5.5rem;
  cursor: pointer;
  width: 2.5rem;

  ${({$inverted}) => $inverted && css`
    margin-left: 2.1875rem;
  `}

  ${({$fullWidth}) => !$fullWidth && css`
    margin-right: 0.75rem;
  `}


  ${({mode}) => mode === 'dark' && css`
    path,
    rect {
      fill: ${({theme}) => theme.colors.secondary};
    }
  `}

  ${({arrowMobilePosition}) => arrowMobilePosition === 'down' && css`
    ${media.down('mobile')(css`
      position: relative;
      margin: 1rem 0;
      bottom: 0;
      left: auto;
    `)}
  `}

  span {
    position: absolute;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
  }

  &:hover {
    transform: scaleX(1.6);
  }

  &::after {
    content: '';
  }

  ${({$inverted}) => $inverted && css`
    margin-left: 5.5rem;

    ${({$fullWidth}) => !$fullWidth && css`
      margin-left: 0.75rem;
    `}
  `}

  ${media.down('mobile')(css`
    margin: 0 1rem;
  `)}
`

const StyledSwiperArrow = styled(ArrowRightIcon)`
  ${({$inverted}) => $inverted && css`
    transform: scale(-1);
  `}
`


const Arrow = ({className, title, mode, arrowMobilePosition, $inverted, $fullWidth}) => (
  <StyledArrowContainer
      className={className}
      mode={mode}
      arrowMobilePosition={arrowMobilePosition}
      $inverted={$inverted}
      $fullWidth={$fullWidth}
  >
    <span className="sr-only">{title}</span>
    <StyledSwiperArrow $inverted={$inverted} />
  </StyledArrowContainer>
)

Arrow.defaultProps = {
  mode: 'default',
  arrowMobilePosition: 'default',
}

Arrow.propTypes = {
  className: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['default', 'dark']),
  arrowMobilePosition: PropTypes.oneOf(['default', 'down']),
  title: PropTypes.string.isRequired,
  $inverted: PropTypes.bool,
  $fullWidth: PropTypes.bool,
}

export default Arrow
